import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';

// material
import {
  Stack,
  TextField,
  Divider,
  IconButton,
  Tooltip,
  Autocomplete,
  Typography,
  Container,
  Grid,
  Box,
  Card,
  CircularProgress,
  Modal,
  Fade,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import Breadcrumbs from '../components/Breadcrumbs';
import FieldOptions from '../components/FieldOptions';
import {
  fetchCabinetFields,
  createDocuQBConfig,
  createBillConfig,
  fetchQBTransactions,
  createOrUpdateBillConfigLine,
  getBillFieldMapping,
  updateDocuQBConfig,
  updateBillConfig,
  getDWClientOfUser,
  getCompanyFeatureFlag,
} from '../services/api';
import SnackbarToast from '../components/Snackbar';

// ----------------------------------------------------------------------

export default function BillFieldMapping() {
  const { id, cabinet } = useParams();
  const navigate = useNavigate();

  const [isEditOperation, setIsEditOperation] = useState(false);
  const [qbBillConfigId, setQbBillConfigId] = useState(false);
  const [dwClientId, setDwClientId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileCabinet, setFileCabinet] = useState(false);
  const [cabinetFieldList, setCabinetFieldList] = useState([]);
  const [qbTransactionList, setQbTransactionList] = useState([]);
  const [configName, setConfigName] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [showError, setShowError] = useState({});
  const [cabinetFieldData, setCabinetFieldData] = useState(false);
  const [lineItemField, setLineItemField] = useState(false);
  const [lineItemFieldList, setLineItemFieldList] = useState([]);
  const [isLineMappingExists, setIsLineMappingExists] = useState(false);
  const [lineName, setLineName] = useState(false);
  const [qbClass, setQbClass] = useState(false);
  const [qbGl, setQbGl] = useState(false);
  const [customerName, setCustomerName] = useState(false);
  const [description, setDescription] = useState(false);
  const [quantity, setQuantity] = useState(false);
  const [rate, setRate] = useState(false);
  const [amount, setAmount] = useState(false);
  const [billableStatus, setBillableStatus] = useState(false);
  const [isBillMappingEnable, setIsBillMappingEnable] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [poFeatureFlag, setPoFeatureFlag] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('userCompanyId');

    getCompanyFeatureFlag({ companyId, featureName: 'Purchase Order' }).then((response) => {
      setPoFeatureFlag(response.data.is_enabled);
    });

    const localFileCabinet = cabinet !== undefined ? cabinet : localStorage.getItem('fileCabinetId');
    setFileCabinet(localFileCabinet);
    setIsLoading(true);

    getDWClientOfUser({ userId, companyId }).then((response) => {
      const dwCompanyId = response.data.id || null;
      setDwClientId(dwCompanyId);
    });

    fetchCabinetFields(localFileCabinet, companyId).then((response) => {
      const preparedList = prepareFieldsList(response.data);
      setCabinetFieldList(preparedList);
      setCabinetFieldData(response.data);

      const apiCabinetData = response.data;

      // Getting Data for Edit Option
      if (id !== undefined && id !== null) {
        getBillFieldMapping({ id })
          .then((result) => {
            if (result.data && result.data.bill_mappings) {
              setFormInitialValues(result.data.bill_mappings, preparedList);
              setIsEditOperation(true);
              setIsBillMappingEnable(true);
              const mappedLineFieldName = result.data.bill_mappings.line_item_field_name || null;
              if (mappedLineFieldName) {
                const lineItemFieldVal = getFieldValueFromId(mappedLineFieldName, preparedList);
                setLineItemField(getFieldValueFromId(mappedLineFieldName, preparedList));
                setIsLineMappingExists(true);

                const mappedConfigName = result.data.bill_mappings.config_name || null;
                if (mappedConfigName) {
                  setConfigName(mappedConfigName);
                }

                const mappedTransactionName = result.data.bill_mappings.qb_transaction || null;
                if (mappedTransactionName) {
                  setTransaction({ id: mappedTransactionName.id, label: mappedTransactionName.label });
                }

                const lineItem = checkLineItemExistence(lineItemFieldVal.id, apiCabinetData);
                if (lineItem) {
                  const tableFields = lineItem.table_field_cols;
                  const lineItemFieldOptions = tableFields
                    ? tableFields.map((item) => {
                        const cabinateFieldItem = {};
                        cabinateFieldItem.id = item.db_name;
                        cabinateFieldItem.label = item.display_name;
                        return cabinateFieldItem;
                      })
                    : [];
                  setLineItemFieldList(lineItemFieldOptions);
                  setIsLineMappingExists(true);

                  const mappedLineName = result.data.bill_mappings.line_name || null;
                  if (mappedLineName) {
                    setLineName(getFieldValueFromId(mappedLineName, lineItemFieldOptions));
                  }

                  const mappedQbClass = result.data.bill_mappings.line_qb_class || null;
                  if (mappedQbClass) {
                    setQbClass(getFieldValueFromId(mappedQbClass, lineItemFieldOptions));
                  }

                  const mappedQbGl = result.data.bill_mappings.line_qb_gl || null;
                  if (mappedQbGl) {
                    setQbGl(getFieldValueFromId(mappedQbGl, lineItemFieldOptions));
                  }

                  const mappedCustomerName = result.data.bill_mappings.line_customer_name || null;
                  if (mappedCustomerName) {
                    setCustomerName(getFieldValueFromId(mappedCustomerName, lineItemFieldOptions));
                  }

                  const mappedDescription = result.data.bill_mappings.line_description || null;
                  if (mappedDescription) {
                    setDescription(getFieldValueFromId(mappedDescription, lineItemFieldOptions));
                  }

                  const mappedRate = result.data.bill_mappings.line_rate || null;
                  if (mappedRate) {
                    setRate(getFieldValueFromId(mappedRate, lineItemFieldOptions));
                  }

                  const mappedAmount = result.data.bill_mappings.line_amount || null;
                  if (mappedAmount) {
                    setAmount(getFieldValueFromId(mappedAmount, lineItemFieldOptions));
                  }

                  const quickBooksBillConfigId = result.data.bill_mappings.qb_bill_config_id || null;
                  if (quickBooksBillConfigId) {
                    setQbBillConfigId(quickBooksBillConfigId);
                  }
                }
              }
            }
            if (result.message) {
              setIsLoading(false);
              setOpenAlert(true);
              setErrorMessage(result.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
      //
    });

    fetchQBTransactions()
      .then((response) => {
        prepareQBTransactionList(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const prepareFieldsList = (data) => {
    const cabinateFieldsOptions = data
      ? data.map((item) => {
          const cabinateFieldItem = {};
          cabinateFieldItem.id = item.db_name;
          cabinateFieldItem.label = item.display_name;
          return cabinateFieldItem;
        })
      : [];
    return cabinateFieldsOptions;
  };

  const getFieldValueFromId = (id, preparedList) => {
    const option = preparedList.find((option) => option.id === id);
    return option ? { id: option.id, label: option.label } : '';
  };

  const prepareQBTransactionList = (data) => {
    const transactionList = data
      ? data.map((item) => {
          const transactionItem = {};
          if (item.transaction_name !== 'Purchase Order') {
            transactionItem.id = item.id;
            transactionItem.label = item.transaction_name;
          }
          return transactionItem;
        })
      : [];
    // remove blank objects from array
    const results = transactionList.filter((element) => {
      if (Object.keys(element).length !== 0) {
        return true;
      }
      return false;
    });
    setQbTransactionList(results);
  };

  const configFormSchema = Yup.object().shape({
    vendorName: Yup.object().required('Vendor Name is required'),
    billDate: Yup.object().required('Bill Date is required'),
    amount: Yup.object().required('Amount is required'),
  });

  const checkLineItemExistence = (lineItemFieldName, cabList) => {
    const foundFieldItem = cabList.find((fieldItem) => fieldItem.db_name === lineItemFieldName);
    if (foundFieldItem && foundFieldItem.table_field_cols) {
      return foundFieldItem;
    }
    return false;
  };

  const saveBillConfig = (formValues) => {
    let qbCompanyId = null;

    if (localStorage.getItem('qbCompany') !== undefined && localStorage.getItem('qbCompany') !== null) {
      qbCompanyId = JSON.parse(localStorage.getItem('qbCompany')).id;
    }

    const docuQbConfigValues = {
      name: configName,
      file_cabinet: fileCabinet,
      qb_company: qbCompanyId,
      dw_client: dwClientId,
      qb_transaction: transaction ? transaction.id : false,
      user: localStorage.getItem('userId'), // TODO : fetch logged in employee id here
    };

    const billConfigValues = {
      bill_no: formValues.billNo && formValues.billNo !== '' ? formValues.billNo.id : '',
      acc_pay: formValues.accPay && formValues.accPay !== '' ? formValues.accPay.id : '',
      vendor_name: formValues.vendorName && formValues.vendorName !== '' ? formValues.vendorName.id : '',
      line_item_field_name: lineItemField && lineItemField !== '' ? lineItemField.id : '',
      terms: formValues.terms && formValues.terms !== '' ? formValues.terms.id : '',
      bill_date: formValues.billDate && formValues.billDate !== '' ? formValues.billDate.id : '',
      due_date: formValues.dueDate && formValues.dueDate !== '' ? formValues.dueDate.id : '',
      category_name: formValues.categoryName && formValues.categoryName !== '' ? formValues.categoryName.id : '',
      item_name: formValues.itemName && formValues.itemName !== '' ? formValues.itemName.id : '',
      customer_name: formValues.customerName && formValues.customerName !== '' ? formValues.customerName.id : '',
      description: formValues.description && formValues.description !== '' ? formValues.description.id : '',
      quantity: formValues.quantity && formValues.quantity !== '' ? formValues.quantity.id : '',
      rate: formValues.rate && formValues.rate !== '' ? formValues.rate.id : '',
      amount: formValues.amount && formValues.amount !== '' ? formValues.amount.id : '',
      location_name: formValues.locationName && formValues.locationName !== '' ? formValues.locationName.id : '',
      class_name: formValues.className && formValues.className !== '' ? formValues.className.id : '',
      memo: formValues.memo && formValues.memo !== '' ? formValues.memo.id : '',
      private_note: formValues.privateNote && formValues.privateNote !== '' ? formValues.privateNote.id : '',
    };

    if (isEditOperation) {
      updateDocuQBConfig(docuQbConfigValues, id).then((response) => {
        if (response.data && response.data.id) {
          const configValues = { ...billConfigValues, docu_qb_config: response.data.id };
          updateBillConfig(configValues, qbBillConfigId)
            .then((response) => {
              if (response.data && response.status && response.status === 200) {
                const billConfigId = (response.data && response.data.id) || null;
                if (isLineMappingExists && billConfigId) {
                  const lineValues = {
                    lineName: (lineName && lineName.id && lineName.id) || null,
                    qbClass: (qbClass && qbClass.id && qbClass.id) || null,
                    qbGl: (qbGl && qbGl.id && qbGl.id) || null,
                    customerName: (customerName && customerName.id && customerName.id) || null,
                    description: (description && description.id && description.id) || null,
                    quantity: (quantity && quantity.id && quantity.id) || null,
                    rate: (rate && rate.id && rate.id) || null,
                    amount: (amount && amount.id && amount.id) || null,
                    qbBillConfigId: billConfigId,
                    billableStatus: (billableStatus && billableStatus.id && billableStatus.id) || null,
                  };
                  console.log(lineValues);
                  createOrUpdateBillConfigLine(lineValues).then(() => {
                    if (isEditOperation) {
                      navigate('/app/bill-config-list');
                    } else {
                      navigate('/app/vendor-maping');
                    }
                  });
                }
                navigate('/app/vendor-maping', { replace: false });
              }
            })
            .catch((error) => {
              console.error('Could not create Docuware QuickBooks Configuration:', error);
              return false;
            });
        }
      });
    } else {
      createDocuQBConfig(docuQbConfigValues)
        .then((response) => {
          if (response.data && response.status && response.status === 201 && response.data.id) {
            const configValues = { ...billConfigValues, docu_qb_config: response.data.id };
            createBillConfig(configValues)
              .then((response) => {
                if (response.data && response.status && response.status === 201) {
                  const billConfigId = (response.data && response.data.id) || null;
                  localStorage.setItem('poConfigId', response.data.docu_qb_config);
                  if (isLineMappingExists && billConfigId) {
                    const lineValues = {
                      lineName: (lineName && lineName.id && lineName.id) || null,
                      qbClass: (qbClass && qbClass.id && qbClass.id) || null,
                      qbGl: (qbGl && qbGl.id && qbGl.id) || null,
                      customerName: (customerName && customerName.id && customerName.id) || null,
                      description: (description && description.id && description.id) || null,
                      quantity: (quantity && quantity.id && quantity.id) || null,
                      rate: (rate && rate.id && rate.id) || null,
                      amount: (amount && amount.id && amount.id) || null,
                      qbBillConfigId: billConfigId,
                      billableStatus: (billableStatus && billableStatus.id && billableStatus.id) || null,
                    };
                    createOrUpdateBillConfigLine(lineValues).then(() => {
                      if (poFeatureFlag) {
                        setOpenModal(true);
                      } else {
                        navigate('/app/vendor-maping', { replace: false });
                      }
                    });
                  }
                  if (poFeatureFlag) {
                    setOpenModal(true);
                  } else {
                    navigate('/app/vendor-maping', { replace: false });
                  }
                }
              })
              .catch((error) => {
                console.error('Could not create Docuware QuickBooks Configuration:', error);
                return false;
              });
          }
          return true;
        })
        .catch((error) => {
          console.error('Could not create Bill Configuration:', error);
          return false;
        });
    }
    return true;
  };

  const formik = useFormik({
    initialValues: {
      accPay: '',
      billNo: '',
      vendorName: '',
      terms: '',
      billDate: '',
      dueDate: '',
      categoryName: '',
      itemName: '',
      customerName: '',
      description: '',
      quantity: '',
      rate: '',
      amount: '',
      locationName: '',
      className: '',
      privateNote: '',
      memo: '',
      line_items: [],
    },
    validationSchema: configFormSchema,
    onSubmit: () => {
      const isSaved = saveBillConfig(formik.values);
    },
  });

  const { errors, handleSubmit, getFieldProps, setFieldValue } = formik;

  const setFormInitialValues = (apiData, preparedList) => {
    // Initialize Formik form with API data
    formik.setValues({
      accPay: getFieldValueFromId(apiData.acc_pay, preparedList),
      billNo: getFieldValueFromId(apiData.bill_no, preparedList),
      vendorName: getFieldValueFromId(apiData.vendor_name, preparedList),
      terms: getFieldValueFromId(apiData.terms, preparedList),
      billDate: getFieldValueFromId(apiData.bill_date, preparedList),
      dueDate: getFieldValueFromId(apiData.due_date, preparedList),
      categoryName: getFieldValueFromId(apiData.category_name, preparedList),
      itemName: getFieldValueFromId(apiData.item_name, preparedList),
      customerName: getFieldValueFromId(apiData.customer_name, preparedList),
      description: getFieldValueFromId(apiData.description, preparedList),
      quantity: getFieldValueFromId(apiData.quantity, preparedList),
      rate: getFieldValueFromId(apiData.rate, preparedList),
      amount: getFieldValueFromId(apiData.amount, preparedList),
      locationName: getFieldValueFromId(apiData.location_name, preparedList),
      className: getFieldValueFromId(apiData.class_name, preparedList),
      privateNote: getFieldValueFromId(apiData.private_note, preparedList),
      memo: getFieldValueFromId(apiData.memo, preparedList),
    });
  };
  const navigates = [];
  if (id) {
    navigates.push({ path: '/app/bill-config-list', name: 'Bill Mapping' });
  } else {
    navigates.push({ path: '/quickbooks/quickbooks_code/', name: 'Configuration' });
  }
  if (!id) {
    navigates.push({ path: '/app/config', name: 'DocuWare Configuration' });
  }
  navigates.push({ path: '', name: 'Configure Bills Transaction' });

  return (
    <Page title="Transaction Config">
      <SnackbarToast open={openAlert} handleClose={() => setOpenAlert(false)} message={errorMessage} type="error" />
      <Card variant="outlined" sx={{ mt: 0 }} style={{ overflow: 'visible', borderRadius: 5 }}>
        <Breadcrumbs separator=">" navigates={navigates} />
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 3, mt: 3 }}>
          <Container style={{ maxWidth: '100%' }}>
            <Typography variant="h5" sx={{ mb: 3 }}>
              Configure Bills Transaction
            </Typography>
            <Stack style={{ marginTop: 20, width: '100%' }} spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        autoComplete="configName"
                        size="small"
                        required
                        type="text"
                        value={configName || null}
                        onChange={(event) => {
                          setShowError({ ...showError, configName: '' });
                          setConfigName(event.target.value);
                        }}
                        label={!configName && 'Configuration Name'}
                        style={{ border: showError.configName ? '1px solid #D43F3E' : '', borderRadius: 6 }}
                      />
                      {showError.configName && (
                        <span style={{ color: '#D43F3E', fontSize: 12 }}>{showError.configName}</span>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip title="Write Configuration name i.e Accounting Configuration">
                        <IconButton>
                          <Icon
                            style={{ color: 'grey', height: '25px', width: '25px' }}
                            icon={'fluent:info-24-regular'}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={qbTransactionList}
                        disabled={false}
                        size={'small'}
                        value={transaction || null}
                        defaultValue={null}
                        onChange={(_event, newValue) => {
                          setTransaction(newValue);
                          setShowError({ ...showError, transaction: '' });
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} label={'QuickBooks Transaction'} />}
                        style={{ border: showError.transaction ? '1px solid #D43F3E' : '', borderRadius: 6 }}
                      />
                      {showError.transaction && (
                        <span style={{ color: '#D43F3E', fontSize: 12 }}>{showError.transaction}</span>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip title="Select QuickBooks transaction for Configuration">
                        <IconButton>
                          <Icon
                            style={{ color: 'grey', height: '25px', width: '25px' }}
                            icon={'fluent:info-24-regular'}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                {isEditOperation === false && (
                  <Grid item xs={12}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      style={{ maxWidth: '100px', minWidth: '100px', maxHeight: '40px', minHeight: '40px' }}
                      onClick={() => {
                        if (configName && transaction) {
                          setIsBillMappingEnable(true);
                        } else {
                          let error = { configName: '', transaction: '' };
                          // eslint-disable-next-line no-unused-expressions
                          configName ? null : (error = { ...error, configName: 'Configuration Name is required' });
                          // eslint-disable-next-line no-unused-expressions
                          transaction ? null : (error = { ...error, transaction: 'QuickBook transaction is required' });
                          setShowError(error);
                        }
                      }}
                    >
                      Next
                    </LoadingButton>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </Container>
        </Stack>
      </Card>
      {id && !isBillMappingEnable && isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress style={{ margin: 10 }} />
        </div>
      )}
      {isBillMappingEnable && (
        <Card variant="outlined" sx={{ mt: 3 }} style={{ overflow: 'visible', borderRadius: 7 }}>
          <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 10, mt: 3 }}>
            <Container style={{ maxWidth: '100%' }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Stack style={{ marginTop: 10, width: 600 }} spacing={2}>
                    <Box sx={{ textAlign: 'left' }}>
                      Please configure QuickBooks field by selecting related DocuWare Field..
                    </Box>
                    <Divider />
                    <Grid container spacing={1}>
                      <Grid item xs={5}>
                        <Typography variant="subtitle2" style={{ marginLeft: 15 }}>
                          QuickBooks Field
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="subtitle2" style={{ marginLeft: 15 }}>
                          DocuWare Field
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        Bill No
                      </Grid>
                      <Grid item xs={7}>
                        <FieldOptions
                          options={cabinetFieldList}
                          placeHolderLabel="Select Docuware Field"
                          size="small"
                          {...getFieldProps('billNo')}
                          onChange={(_event, value) => {
                            setFieldValue('billNo', value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        Vendor Name *
                      </Grid>
                      <Grid item xs={7}>
                        <FieldOptions
                          options={cabinetFieldList}
                          placeHolderLabel="Select Docuware Field"
                          size="small"
                          error={errors.vendorName}
                          {...getFieldProps('vendorName')}
                          onChange={(event, value) => {
                            setFieldValue('vendorName', value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        Terms
                      </Grid>
                      <Grid item xs={7}>
                        <FieldOptions
                          options={cabinetFieldList}
                          placeHolderLabel="Select Docuware Field"
                          size="small"
                          {...getFieldProps('terms')}
                          onChange={(_event, value) => {
                            setFieldValue('terms', value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        Bill Date *
                      </Grid>
                      <Grid item xs={7}>
                        <FieldOptions
                          options={cabinetFieldList}
                          placeHolderLabel="Select Docuware Field"
                          size="small"
                          error={errors.billDate}
                          {...getFieldProps('billDate')}
                          onChange={(_event, value) => {
                            setFieldValue('billDate', value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        Due Date
                      </Grid>
                      <Grid item xs={7}>
                        <FieldOptions
                          options={cabinetFieldList}
                          placeHolderLabel="Select Docuware Field"
                          size="small"
                          {...getFieldProps('dueDate')}
                          onChange={(_event, value) => {
                            setFieldValue('dueDate', value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        Amount *
                      </Grid>
                      <Grid item xs={7}>
                        <FieldOptions
                          options={cabinetFieldList}
                          placeHolderLabel="Select Docuware Field"
                          size="small"
                          error={errors.amount}
                          {...getFieldProps('amount')}
                          onChange={(_event, value) => {
                            setFieldValue('amount', value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        Location Name
                      </Grid>
                      <Grid item xs={7}>
                        <FieldOptions
                          options={cabinetFieldList}
                          placeHolderLabel="Select Docuware Field"
                          size="small"
                          {...getFieldProps('locationName')}
                          onChange={(_event, value) => {
                            setFieldValue('locationName', value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        Private Note
                      </Grid>
                      <Grid item xs={7}>
                        <FieldOptions
                          options={cabinetFieldList}
                          placeHolderLabel="Select Docuware Field"
                          size="small"
                          {...getFieldProps('privateNote')}
                          onChange={(_event, value) => {
                            setFieldValue('privateNote', value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        Line Items
                      </Grid>
                      <Grid item xs={7}>
                        <FieldOptions
                          options={cabinetFieldList}
                          value={lineItemField}
                          placeHolderLabel="Select Line Item"
                          size="small"
                          onChange={(_event, value) => {
                            setLineItemField(value);
                            if (value && value.id) {
                              const lineItem = checkLineItemExistence(value.id, cabinetFieldData);
                              if (lineItem === false) {
                                alert('This field does not contain line item values');
                                setLineItemField(null);
                                setIsLineMappingExists(false);
                              } else {
                                const tableFields = lineItem.table_field_cols;
                                const lineItemFieldOptions = tableFields
                                  ? tableFields.map((item) => {
                                      const cabinateFieldItem = {};
                                      cabinateFieldItem.id = item.db_name;
                                      cabinateFieldItem.label = item.display_name;
                                      return cabinateFieldItem;
                                    })
                                  : [];
                                setLineItemFieldList(lineItemFieldOptions);
                                setIsLineMappingExists(true);
                              }
                            }
                          }}
                        />
                      </Grid>
                      {isLineMappingExists && (
                        <>
                          <Grid item xs={5} style={{ marginTop: 10 }}>
                            <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                              Line Mapping
                            </Typography>
                          </Grid>
                          <Grid item xs={7} />
                          <Grid item xs={5}>
                            Line Name
                          </Grid>
                          <Grid item xs={7}>
                            <FieldOptions
                              options={lineItemFieldList}
                              placeHolderLabel="Select Line Name"
                              value={lineName}
                              size="small"
                              onChange={(_event, value) => {
                                setLineName(value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            QB Class
                          </Grid>
                          <Grid item xs={7}>
                            <FieldOptions
                              options={lineItemFieldList}
                              placeHolderLabel="Select QB Class"
                              value={qbClass}
                              size="small"
                              onChange={(_event, value) => {
                                setQbClass(value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            QB GL
                          </Grid>
                          <Grid item xs={7}>
                            <FieldOptions
                              options={lineItemFieldList}
                              placeHolderLabel="Select QB GL"
                              value={qbGl}
                              size="small"
                              onChange={(_event, value) => {
                                setQbGl(value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            Customer Name
                          </Grid>
                          <Grid item xs={7}>
                            <FieldOptions
                              options={lineItemFieldList}
                              placeHolderLabel="Select Customer Name"
                              value={customerName}
                              size="small"
                              onChange={(_event, value) => {
                                setCustomerName(value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            Description
                          </Grid>
                          <Grid item xs={7}>
                            <FieldOptions
                              options={lineItemFieldList}
                              placeHolderLabel="Select Description"
                              value={description}
                              size="small"
                              onChange={(_event, value) => {
                                setDescription(value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            Rate
                          </Grid>
                          <Grid item xs={7}>
                            <FieldOptions
                              options={lineItemFieldList}
                              placeHolderLabel="Select Rate"
                              value={rate}
                              size="small"
                              onChange={(_event, value) => {
                                setRate(value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            Quantity
                          </Grid>
                          <Grid item xs={7}>
                            <FieldOptions
                              options={lineItemFieldList}
                              placeHolderLabel="Select Quantity"
                              value={quantity}
                              size="small"
                              onChange={(_event, value) => {
                                setQuantity(value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            Amount
                          </Grid>
                          <Grid item xs={7}>
                            <FieldOptions
                              options={lineItemFieldList}
                              placeHolderLabel="Select Amount"
                              value={amount}
                              size="small"
                              onChange={(_event, value) => {
                                setAmount(value);
                              }}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          style={{ maxWidth: '100px', minWidth: '100px', maxHeight: '40px', minHeight: '40px' }}
                        >
                          Save
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Stack>
                </Form>
              </FormikProvider>
            </Container>
          </Stack>
        </Card>
      )}
      {/* ---------Create Modal Start--------- */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={openModal}>
          <Card variant="outlined" sx={{ mt: 0, width: '40%' }} style={{ borderRadius: 5, padding: 15 }}>
            <Typography variant="h5" style={{ marginLeft: 20, marginTop: 10, marginBottom: -10 }}>
              Do you want to Configuration Purchase Order?
            </Typography>
            <div style={{ margin: 15 }}>
              <div style={{ display: 'flex', margin: 20, justifyContent: 'center', marginLeft: 10 }}>
                <LoadingButton
                  fullWidth
                  onClick={() => {
                    setOpenModal(false);
                    navigate('/app/purchase-order-mapping', { replace: false });
                  }}
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    maxWidth: '100px',
                    minWidth: '100px',
                    maxHeight: '40px',
                    minHeight: '40px',
                    marginRight: 10,
                  }}
                  loading={false}
                >
                  Yes
                </LoadingButton>
                <LoadingButton
                  onClick={() => {
                    setOpenModal(false);
                    navigate('/app/vendor-maping', { replace: false });
                  }}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    maxWidth: '100px',
                    minWidth: '100px',
                    maxHeight: '40px',
                    minHeight: '40px',
                    backgroundColor: '#D43F3E',
                  }}
                  loading={false}
                >
                  No
                </LoadingButton>
              </div>
            </div>
          </Card>
        </Fade>
      </Modal>
      {/* --------Create Modal End------ */}
    </Page>
  );
}
